html,
body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans";
}

div[class^="Portal__AppScene"] {
  height:100vh
}

p {
  margin: 0;
  padding: 0;
}
